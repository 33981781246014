import * as React from "react"
import LayoutEmergency from "../components/layout-emergency"

const EmergencyPage = () => {
  return (
    <LayoutEmergency>

    </LayoutEmergency>
  )
}

export default EmergencyPage